import { useConfigContext } from 'contexts/ConfigContext';
import React, { ReactNode, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './Layout.scss';
const globeIcon = require('../assets/img/icon_globe_transparent_web.png');
const Header = () => {
    const [modal, setModal] = useState<boolean>(false);
    const { externalLinks } = useConfigContext();
    const toggle = () => setModal(!modal);
    const closeBtn = (
        <Button className="popup-close" onClick={toggle}>
            &times;
        </Button>
    );
    return (
        <>
            <header>
                <div id="navbar">
                    <div className="nav-routes">
                        <a
                            href={externalLinks?.aboutUs}
                            title="About Us"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            ABOUT US
                        </a>
                        <span className="px-4">|</span>
                        <a
                            href={externalLinks?.contactUs}
                            title="Contact Us"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            CONTACT US
                        </a>
                    </div>
                    <div className="language-picker">
                        <img src={globeIcon} alt="globe icon" />
                        &nbsp;
                        <a href="#" onClick={toggle} id="spanishDialogButton">
                            ESPAÑOL
                        </a>
                    </div>
                </div>
            </header>
            <Modal
                id="spanish-popup"
                isOpen={modal}
                toggle={toggle}
                fade={false}
            >
                <ModalHeader toggle={toggle} close={closeBtn}>
                    ESPAÑOL
                </ModalHeader>
                <ModalBody className="text-center">
                    <p>
                        Tiene el derecho de obtener esta información y ayuda en
                        su idioma en forma gratuita.
                    </p>
                    <p>
                        Miembros actuales del Alignment Health Plan, por favor
                        llame al número de Servicios para Miembros que figura en
                        su tarjeta de identificación para obtener ayuda.
                    </p>

                    <p>
                        Miembros potenciales deben llamar al Departamento de
                        Televentas al 1-888-979-2247 (TTY/TDD: 711).
                    </p>
                    <p>
                        Alignment Health Plan está disponible de 8:00 a.m. a
                        8:00 p.m., los siete días de la semana del 1° de Octubre
                        hasta el 31 de Marzo (excepto el Día de Acción de
                        Gracias y Navidad). Del 1° de abril al 30 de septiembre,
                        el horario es de 8:00 a.m. a 8:00 p.m. de lunes a
                        viernes (excepto feriados).
                    </p>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-center">
                    <Button
                        className="popup-cancel"
                        color="secondary"
                        onClick={toggle}
                    >
                        CERRAR
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
const Footer = () => {
    const { externalLinks } = useConfigContext();

    const lastUpdated = '10/01/2021';
    const serialNumber = 'Y0141_22139EN';
    return (
        <footer>
            <hr />
            <div id="disclaimer-container">
                <p>
                    Alignment Health Plan is an HMO, HMO POS, HMO C-SNP, HMO
                    D-SNP and PPO plan with a Medicare contract and a contract
                    with the California, Nevada and North Carolina Medicaid
                    programs. Enrollment in Alignment Health Plan depends on
                    contract renewal.{' '}
                    <a
                        id="disclaimer-link"
                        href={externalLinks?.disclaimer}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Click here to read the full disclaimer.
                    </a>
                </p>
            </div>
            <div id="metadata-container">
                <span>
                    ©Copyright 2023 Alignment Health Plan. All Rights Reserved
                </span>
                <span>
                    Page last updated on {lastUpdated} | {serialNumber}
                </span>
            </div>
        </footer>
    );
};
interface LayoutProps {
    children?: ReactNode;
}
const Layout = ({ children }: LayoutProps) => {
    return (
        <div id="content-wrap">
            <Header />
            {children}
            <Footer />
        </div>
    );
};
export default Layout;
